
.section1 {
    width: 200px;
    height: 624px;
    background-color: white;
    padding: 30px;
    border-radius: 5px;
  }
  
  .section2 {
    width: 200px;
    height: 624px;
    background-color: #05071E;
    color: white;
    padding:40px;
    justify-content: center; /* Center horizontally */
    align-items: center;
    border-radius: 6px;
  }
  

  .flex-container {
  
    background-color: black; /* Center vertically */
  }
  
  
  
  /* Add this CSS to your existing CSS file or create a new one */
/* Add this CSS to your existing CSS file or create a new one */
.icon-input {
    display: flex;
    align-items: center;
    
  }
  .btn{
    border-radius: 30px;

  }
  .custom-container {
    
    padding-top:50px !important;
    padding-bottom:50px !important;
    background-color: rgba(0, 2, 6, 5) !important; /* Updated background color with transparency */
    backdrop-filter: blur(10px) !impor;
    /* You can add more styles as needed */
  }
  
  
  
  .custom-input {
    position: relative;
  }
  
  .custom-input i {
    position: absolute;
    left: 0px; /* Adjust the left position as needed */
    top: 50%;
    transform: translateY(-50%);
    color: black;
    background-color: grey; /* Change the background color as needed */
    padding: 16px; /* Adjust the padding as needed */
    border-radius: 3px; /* Change the color as needed */
  }
  
  .custom-input input {
    padding-left: 30px;
   
  
     /* Adjust the padding to make space for the icon */
  }
 
  #id ,label {
    color: white;
  }
  
  .flex-container {
    display: flex;
    align-items:center; /* To evenly distribute the cards */
    background-color: rgba(0, 0, 0, 0); /* Transparent black background */
    padding: 10px; /* Add some padding to separate the cards from the edges */
  }
  .form-group label {
    text-align: left; /* Align label text to the left */
    margin-right: 10px; /* Add some space between label and input */
  }

  
  
  
  