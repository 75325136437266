/* custom.css */
/* Remove padding and margin */
.navbar-no-padding {
    padding: 0 !important;
    margin: 0 !important;
   
  }
  
  /* Adjust Navbar width if needed */
  .navbar-custom-width {
    width: 100%; /* Set your desired width here */
  }


  