* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --color-131921: #05071E;
  --color-febd69: #febd69;
  --color-3b41449: #3b4149;
  --color-232f3e: #232f3e;
}
body{
  font-family: "Rubik", sans-serif;
}
a {
  text-decoration:none;
  display: inline-block;
}
.form-control:focus {
  box-shadow: none;
  border-color:
}
.header-top-strip{
  background-color : var( --color-131921);
  border-bottom: 2px solid var(--color-3b41449);
}

.header-upper{
  background-color : var( --color-131921);
  
}
.header-upper .input-group-text {
  background-color:var(--color-febd69)
}
.header-upper .header-upper-links img {
  width: 38px;
  height:30px;
}
.header-bottom {
  background-color: var(--color-232f3e)
}
.gap-10 {
  gap: 20px;
}
.gap-15 {
  gap: 15px;
}
.header-bottom .menu-links a {
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight:400;
  letter-spacing: 0.3;
  text-transform: uppercase;
}